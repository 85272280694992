import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { BoxDanger, BoxInfo, MnemoLoading, SupportoTecnicoLive, Title } from 'components/misc';
import { CourseHelper, dateHelper, learningLiveService, networkErrorHelper } from 'libs';
import { Button, Card, CardTitle, CardBody, Icon } from 'design-react-kit';
import { ROUTES } from 'const';

class LessonLiveDetail extends Component {

    state = {
        rCode: '',
        idLessonLive: 0,
        loading: true,
        loadingError: false,
        userCourse: null,
        lessonLive: null,
        fileSlides: null,
        fileTraining: null,
        lessonLiveAttendance: null,
        showFull: true
    }

    loadRemote(id, rCode) {
        learningLiveService
            .myLesson(id, rCode)
            .then(({ data }) => {

                const { userCourse, lessonLive, fileSlides, fileTraining, lessonLiveAttendance } = data.payload;
                const showFull = (!lessonLive.isOnline && CourseHelper.isCFUAll(userCourse.courseCode)) ? false : true;
                this.setState({
                    userCourse,
                    lessonLive,
                    fileSlides,
                    fileTraining,
                    lessonLiveAttendance,
                    loading: false,
                    showFull
                });
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loadingError: true, loading: false });
            });
    }

    componentDidMount() {
        const { idLessonLive, rCode } = this.props.match.params;
        this.setState({
            idLessonLive,
            rCode
        });

        this.loadRemote(idLessonLive, rCode);
    }

    generateAttendee = () => {
        this.setState({ loading: true });
        const { idLessonLive, rCode } = this.state;
        learningLiveService.generateAttendance({ idLessonLive, registrationCode: rCode })
            .then(({ data }) => {
                this.setState({
                    lessonLiveAttendance: data.payload.lessonLiveAttendance,
                    loading: false

                });
            })
            .catch(error => {
                this.setState({ loading: false });
                networkErrorHelper.notify(error);
            });
    }

    downloadAttendee = () => {

        const { lessonLiveAttendance } = this.state;
        if (lessonLiveAttendance) {
            this.setState({ loading: true });
            learningLiveService.donwloadAttendance(lessonLiveAttendance.id)
                .then(({ data }) => {
                    this.setState({ loading: false });
                    // window.location = lessonLiveAttendance.location;
                    window.open(lessonLiveAttendance.location, '_blank');
                })
                .catch(error => {
                    this.setState({ loading: false });
                    networkErrorHelper.notify(error);
                });
        }
    }

    openFile(file) {

        const { rCode } = this.state;
        const location = `${ROUTES.COURSE_SHOW_FILE}/${rCode}/${file.id}`;
        window.location = location;
    };

    downloadFile(file) {

        window.location = file.location;
    };

    render() {
        const {
            lessonLive, fileTraining, fileSlides,
            loading, loadingError, rCode,
            lessonLiveAttendance, showFull } = this.state;

        if (loading) return <MnemoLoading />;

        let to = (lessonLive.isOnline) ? ROUTES.COURSE_LESSONLIVE_SCHEDULE_ONLINE : ROUTES.COURSE_LESSONLIVE_SCHEDULE_OFFLINE;
        to += '/' + rCode;

        return (
            <>
                <div>
                    <NavLink to={to} className="mx-5 btn btn-outline-primary float-right">
                        <Icon icon="it-presentation" /> TORNA ALLE LEZIONI</NavLink>
                    <Title >{lessonLive && lessonLive.title}</Title>
                </div>
                <div className='p-5'>
                    {loadingError && <BoxDanger className="mt-4">
                        Si è verificato un errore durante il caricamento della lezione, se il problema persiste
                        <SupportoTecnicoLive />
                    </BoxDanger>}
                    {lessonLive && (
                        <div>
                            <Card noWrapper={false} tag="div" className="card-bg card-big rounded shadow border-bottom-card mb-5">
                                <div className="flag-icon" />
                                <div className="etichetta">
                                    <span >LEZIONE</span>
                                </div>
                                <CardBody tag="div">
                                    <CardTitle tag="h5">{lessonLive.title}</CardTitle>
                                    <ul>
                                        {lessonLive.ssd && (<li>{lessonLive.ssd}</li>)}
                                        {lessonLive.teacher && (<li>{lessonLive.teacher}</li>)}
                                        <li>{dateHelper.toITDate(lessonLive.date)} - ore inizio {dateHelper.toShortTime(lessonLive.hourStart)}</li>
                                    </ul>
                                </CardBody>
                            </Card>
                            <Card noWrapper={false} tag="div" className="card-bg card-big rounded shadow border-bottom-card mb-5">
                                <div className="flag-icon" />
                                <div className="etichetta">
                                    <span >MATERIALE DIDATTICO</span>
                                </div>
                                <CardBody tag="div">
                                    {(fileSlides && fileSlides.length) > 0 ?
                                        <>
                                            {fileSlides.map((file, index) => (
                                                /*return <li><a key={index} href={file.location} target="_blank" rel="noopener noreferrer">{file.originalName}</a></li>*/

                                                <BoxInfo className="my-0 mnemo-dopzone-boxInfo" key={`file-${file.id}`}>
                                                    {file.originalName}
                                                    <Button
                                                        className="mx-1"
                                                        color="primary bg-dark"
                                                        size="xs"
                                                        onClick={() => this.openFile(file)}>
                                                        Apri
                                                    </Button>
                                                </BoxInfo>
                                            ))
                                            }
                                        </>
                                        :
                                        <><p>Nessuna materiale didattico caricato</p></>}
                                </CardBody>
                            </Card>
                            {showFull && (<>
                                <Card noWrapper={false} tag="div" className="card-bg card-big rounded shadow border-bottom-card mb-5">
                                    <div className="flag-icon" />
                                    <div className="etichetta">
                                        <span >ESERCITAZIONI</span>
                                    </div>
                                    <CardBody tag="div">
                                        {(fileTraining && fileTraining.length) > 0 ?
                                            <>
                                                {fileTraining.map((file, index) => (
                                                    /*return <li><a key={index} href={file.location} target="_blank" rel="noopener noreferrer">{file.originalName}</a></li>*/

                                                    <BoxInfo className="my-0 mnemo-dopzone-boxInfo" key={`file-${file.id}`}>
                                                        {file.originalName}
                                                        <Button
                                                            className="mx-1"
                                                            color="primary bg-dark"
                                                            size="xs"
                                                            onClick={() => this.downloadFile(file)}>
                                                            Scarica
                                                        </Button>
                                                    </BoxInfo>
                                                ))
                                                }
                                            </>
                                            :
                                            <><p>Nessuna esercitazione prevista al momento</p></>}

                                    </CardBody>
                                </Card>

                                <Card noWrapper={false} tag="div" className="card-bg card-big rounded shadow border-bottom-card mb-5">
                                    <div className="flag-icon" />
                                    <div className="etichetta">
                                        <span >ATTESTATO DI PARTECIPAZIONE</span>
                                    </div>
                                    <CardBody tag="div">
                                        {lessonLive.enableAttendance ?
                                            <>
                                                {(lessonLiveAttendance ? <>
                                                    <p>Attestato di partecipazione generato il {dateHelper.toITDate(lessonLiveAttendance.dateGeneration)}. Scaricato {lessonLiveAttendance.countDownload} volte.</p>
                                                    <Button onClick={this.downloadAttendee} >Scarica attestato di partecipazione</Button>
                                                </> : <>
                                                    <Button onClick={this.generateAttendee} >Genera attestato di partecipazione</Button>
                                                </>)}
                                            </> : <p>Attestato di partecipazione non ancora disponibile</p>
                                        }
                                    </CardBody>
                                </Card>
                            </>)}
                        </div>
                    )}
                </div >
            </>
        );
    }
}
export default withRouter(LessonLiveDetail);