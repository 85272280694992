import React, { Component } from "react";
import DayJS from 'react-dayjs';
import ReactHtmlParser from 'react-html-parser';

import { MnemoLoading, BoxDanger, TimeWatcher, SupportoTecnico, MSection } from "components/misc";
import { Link, withRouter } from 'react-router-dom';
import { CourseHelper, learningService, networkErrorHelper } from "libs";

import {
    Container,
    Row, Col,
    Card, CardBody,
    CardFooter
} from 'design-react-kit';
import { MnemoCallout } from "components/misc/MnemoCallout";
import { CoursePlugin } from "libs/plugin";
import { COMPETITION_CLASS_TITLE } from "const";

class CourseDashboard extends Component {

    state = {
        rCode: 0,
        myCourse: null,
        course: null,
        loading: true,
        loadingError: false
    };

    componentDidMount() {
        // get registration code from parameter
        const { rCode } = this.props.match.params;
        this.setState({ rCode: rCode });
        this.loadRemote(rCode);
    }

    async loadRemote(rCode) {
        // get info about course (from server)
        this.loadCourse(rCode);
    }

    loadCourse(rCode) {
        learningService
            .courseDashboard(rCode)
            .then(async ({ data }) => {

                const { cards, notices } = await CoursePlugin.getDashboardCards(data.payload.course, data.payload.userCourse);

                this.setState({ course: data.payload.course, myCourse: data.payload.userCourse, cards, notices, loading: false });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                } else {
                    this.setState({ loadingError: true });
                }

                this.setState({ loading: false });
            });
    }

    render() {

        const {
            loading, loadingError, rCode,
            myCourse, course, cards, notices
        } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);

        const showTimer = (course && course.metadata && course.metadata.showTimer) ? true : false;

        let showReggioLogo = false;        
        if (course && course.metadata){
            if (CourseHelper.isReggio(course.code) || CourseHelper.isReggioBonifico(course.code)) 
                showReggioLogo=true;
        } 
        
        const showUnicamillusLogo = (course && course.metadata && CourseHelper.isUnicamillus30CFU(course.code)) ? true : false;
        const showTchaikovskyLogo = (course && course.metadata && CourseHelper.isTchaikovsky(course.code)) ? true : false;
        return (
            <>
                {loadingError ? (<BoxDanger>Si è verificato un errore durante
                    il caricamento dei dati del corso, se il problema persiste puoi <SupportoTecnico /></BoxDanger>) : (
                    <>
                        <Container className="mt-2">
                            <h5 className="m-4">{ReactHtmlParser(course.title)}</h5>
                            <MnemoCallout>
                                <Row>
                                    <Col md={8}>
                                        <b>Codice registrazione</b>: {rCode}<br />
                                        <b>Codice corso</b>: {course.code}<br />
                                        <b>data Inizio</b>: <DayJS format="DD/MM/YYYY">{myCourse.dateStart}</DayJS><br />
                                        {(myCourse.pluginConfig && myCourse.pluginConfig.competitionClass) 
                                        && (<>
                                        <b>Classe di concorso</b>: {myCourse.pluginConfig.competitionClass} - {COMPETITION_CLASS_TITLE[myCourse.pluginConfig.competitionClass]}
                                        </>)}
                                        {(showTimer > 0) && (<TimeWatcher rCode={rCode} metadata={course.metadata} />)}
                                    </Col>
                                    <Col md={4} className="text-right">
                                        {(showReggioLogo) && (<img src="/img/partners/Unidarc_logo.png" className="image-banner" alt="Universita per Stranieri Dante Alighieri" />)}
                                        {(showUnicamillusLogo) && (<img src="/img/partners/Unicamillus_logo.png" className="image-banner" alt="Universita Unicamillus" />)}
                                        {(showTchaikovskyLogo) && (<img src="/img/partners/Tchaikovsky_logo.jpg" className="image-banner" alt="Conservatorio Statale di Musica Tchaikovsky" />)}
                                    </Col>
                                </Row>
                            </MnemoCallout>
                        </Container>
                        <section id="course-sections">
                            <div className="my-4">
                                <Container>
                                    <Row>
                                        {cards.map((card, i) => (
                                            <Col size="12" xs={12} sm={4} md={3} xl={2} key={`card-${i}`}>
                                                <article className="mb-4 card-space">
                                                    <Card
                                                        className="no-after card-bg rounded shadow border-bottom-card text-center"
                                                        noWrapper={false}
                                                        tag="div"
                                                    >
                                                        <Link className="wrapper-link" to={`${card.link}/${rCode}`}>
                                                            <img className={card.className} alt={card.title} src={card.img}></img>
                                                            <CardBody tag="div" className="minH-card-body">
                                                                <CardFooter tag="h6">
                                                                    {card.title}
                                                                </CardFooter>
                                                            </CardBody>
                                                        </Link>
                                                    </Card>
                                                </article>
                                            </Col>
                                        ))}
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        {(notices && notices.length > 0) && (<>
                            <section>
                                <MSection>
                                    {(notices.length===1) ? <h4>ATTENZIONE: Avviso importante</h4> : <h4>ATTENZIONE: Avvisi importanti</h4>}<hr />
                                    {notices.map((notice, i) => (
                                        <div className="m-3" key={`notice-${i}`}>
                                            <MnemoCallout >
                                                {notice.title && (<>
                                                    <h5>{ReactHtmlParser(notice.title)}</h5>
                                                    <hr />
                                                </>)} 
                                                {ReactHtmlParser(notice.content)}
                                            </MnemoCallout>
                                        </div>
                                    ))}
                                </MSection>

                            </section>
                        </>)}
                    </>
                )}

            </>
        );
    }
}

export default withRouter(CourseDashboard);